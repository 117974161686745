import React from 'react';
import styled from 'styled-components';
import { breakpoint, color, typography } from '../../theme';

const Container = styled.div`

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${typography.family.title};
    margin-top: 2em;
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: ${typography.size.f4};

    @media ${breakpoint.md} {
      font-size: ${typography.size.f3};
    }
  }

  h3 {
    font-size: ${typography.size.f5};

    @media ${breakpoint.md} {
      font-size: ${typography.size.f4};
    }
  }


  p, ul
    {
    color: ${color.grey[90]};
    line-height: ${typography.height.md};
    margin-bottom: 1em;
    font-size: ${typography.size.f6};

    @media ${breakpoint.md} {
      font-size: ${typography.size.f5};
    }
  }

  ul {
    padding-inline-start: 2em;
  }

  a {
    color: ${color.brand[70]};
    text-decoration: underline solid ${color.brand[70]};
    text-decoration-thickness: 1px;
    text-underline-position: under;
    
    @media (hover:hover) {
      :hover {
        color: ${color.brand[50]};
        text-decoration-color: ${color.brand[50]};
      }
    }
  }

  section {
    margin-bottom: 4rem;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const Prose = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  );
};
