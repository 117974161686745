import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import styled from 'styled-components';
import Layout from '../layouts/Layout';
import { typography, breakpoint, radius, color, width, pixels, } from '../theme';
import SEO from '../components/core/Seo';
import { Prose } from '../components/general/Prose';

const Wrapper = styled.div`
  background-color: ${color.grey[20]};
  padding-bottom: 4rem;
`;

const Section = styled.section`
  margin: 0 auto;
  width: 88%;
  max-width: ${props => props.image ? `${width.xl}rem` : `${width.md}rem`};
  padding: 1rem 0;
  padding-top: ${props => props.header && '4rem'};

  @media ${breakpoint.md} {
    padding: 2rem 0;
    padding-top: ${props => props.header && '6rem'};

  }
  @media ${breakpoint.lg} {
    padding: 3rem 0;
    padding-top: ${props => props.header && '8rem'};
  }
`;

const Header = styled.header`
  margin-bottom: 1.5em;
  width: 90%;


  @media ${breakpoint.sm} {
    width: 75%;
  }

  @media ${breakpoint.md} {
    width: 88%;
    margin-bottom: 2em;
  }

  @media ${breakpoint.lg} {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-family: ${typography.family.title};
  font-size: ${typography.size.f3};
  line-height: 1.1;
  color: ${color.grey[100]};
  text-shadow: 0.04em 0.04em 0 ${color.grey[0]};

  @media ${breakpoint.sm} {
    font-size: ${typography.size.f2};
  }

  @media ${breakpoint.md} {
    font-size: ${typography.size.f1};
  }

  @media ${breakpoint.lg} {
    font-size: ${typography.size.f0};
  }
`;

const MetaWrapper = styled.div`
  padding: 0.5em 0;
  border-top: ${pixels.one} solid ${color.brand[70]};
  border-bottom: ${pixels.one} solid ${color.brand[70]};
  color: ${color.brand[70]};
  text-align: right;

  @media ${breakpoint.md} {
    padding: 1em 0;
  }
`;

const MetaContainer = styled.p`
  font-size: ${typography.size.f8};

  @media ${breakpoint.md} {
    font-size: ${typography.size.f7};
  }
`;
const Meta = styled.span`
  font-size: ${typography.size.f8};

  @media ${breakpoint.md} {
    font-size: ${typography.size.f7};
  }
`;

const Image = styled(GatsbyImage)`
  border-radius: ${radius.md};
`;

const Caption = styled.figcaption`
  color: ${color.grey[70]};
  text-align: right;
  font-size: ${typography.size.f8};
  margin-top: 0.25em;
  margin-bottom: -0.5em;

  @media ${breakpoint.md} {
    font-size: ${typography.size.f7};
  }

`;

const Aside = styled(MetaWrapper)`
  margin-top: 6rem;
  text-align: left;
  font-size: ${typography.size.f7};

  @media ${breakpoint.md} {
    font-size: ${typography.size.f6};
  }  
  a {
    text-decoration: none;
  }
  @media (hover:hover) {
    a:hover {
      text-decoration: underline;
    }
  }
`;

const MetaDate = ({ metaTitle, itemProp, dateTime, metaDate }) => {
  return (
    <MetaContainer>
      {metaTitle}: <b><Meta itemProp={itemProp} dateTime={dateTime}>{metaDate}</Meta></b>
    </MetaContainer>
  );
};

export default class Post extends Component {
  render() {
    const { markdownRemark } = this.props.data;
    const post = markdownRemark.frontmatter;

    const schema = {
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      'author': 'Natalie Kerr',
      'headline': post.title,
      'description': markdownRemark.excerpt,
      'articleBody': markdownRemark.rawMarkdownBody,
      'wordcount': markdownRemark.wordCount.words,
      'publisher': 'Natalie Kerr',
      'datePublished': post.rawDate,
      'dateModified': post.rawUpdate,
      'image': post.postImage.heroImage.childImageSharp.original.src,
    };

    return (
      <Layout>
        <SEO
          title={post.title}
          description={markdownRemark.excerpt}
          image={post.postImage.heroImage.childImageSharp.original.src}
          imageAlt={post.postImage.alt}
          schemaMarkup={schema}
          canonical={`https://knotsmassage.co.uk/blog/${post.url}/`}
        />
        <Wrapper>
          <article itemScope itemType="http://schema.org/BlogPosting">
            <Section header>
              <Header>
                <Title itemProp="headline">{post.title}</Title>
              </Header>
              <MetaWrapper>
                <MetaDate metaTitle="Published" itemProp="datePublished" dateTime={post.rawDate} metaDate={post.displayDate} />
                {post.rawUpdate != '' &&
                  <MetaDate metaTitle="Updated" itemProp="dateModified" dateTime={post.rawUpdate} metaDate={post.displayUpdate} />
                }
              </MetaWrapper>
            </Section>
            <Section image={true}>
              <Image
                image={post.postImage.heroImage.childImageSharp.gatsbyImageData}
                alt={post.postImage.alt}
                title={post.postImage.title}
                itemProp="image"
                backgroundColor={`${color.grey[30]}`}
              />
              {post.postImage.caption &&
                <Caption>{post.postImage.caption}</Caption>
              }
            </Section>

            <Section>
              <Prose>
                <section itemProp="articleBody" dangerouslySetInnerHTML={{ __html: markdownRemark.html }}></section>
              </Prose>
              <Aside><Link to="/blog/">{'<'} Back to all posts</Link></Aside>
            </Section>
          </article>
        </Wrapper>
      </Layout>
    );
  }
}

export const query = graphql`query postQuery($url: String!) {
  markdownRemark(frontmatter: {url: {eq: $url}}) {
    excerpt(pruneLength: 120)
    frontmatter {
      title
      rawDate: date
      displayDate: date(formatString: "Do MMM YYYY")
      rawUpdate: update
      displayUpdate: update(formatString: "Do MMM YYYY")
      url
      postImage {
        alt
        title
        caption
        heroImage {
          childImageSharp {
            gatsbyImageData(width: 1200, quality: 70, placeholder: NONE, layout: CONSTRAINED)
            original {
              src
            }
          }
        }
      }
    }
    html
    rawMarkdownBody
    wordCount {
      words
    }
  }
}
`;
